/* Styles pour le conteneur principal du formulaire */
.container {
  width: 80vw;
  height: 90vh;
  padding: 25px;
  background-color: #223E69;
  color: white;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

/* Styles pour le titre du formulaire */
.form-title {
  text-align: center;
  margin-bottom: 25px;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Marges en bas des éléments du formulaire */
.mb-3 {
  margin-bottom: 1rem;
}

/* Styles pour les labels du formulaire */
.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: white;
}

/* Styles pour les champs de formulaire */
.form-control {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

/* Styles pour les champs de formulaire en focus */
.form-control:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Styles pour les groupes de formulaire alignés horizontalement */
.form-group-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

/* Styles pour les groupes de formulaire */
.form-group {
  margin-bottom: 1rem;
}

/* Styles pour les éléments de template */
.template-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

/* Styles pour chaque élément de template */
.template-item {
  flex: 1;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Styles pour l'aperçu des templates */
.template-preview {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

/* Styles pour les éléments de fichier */
.file-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

/* Styles pour chaque groupe de fichiers */
.file-container .form-group {
  flex: 1;
  min-width: 150px;
}

/* Styles pour le bouton primaire */
.btn-primary {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

/* Styles pour le bouton primaire au survol */
.btn-primary:hover {
  background-color: #0056b3;
}

/* Styles pour le message de confirmation */
.form-message {
  text-align: center;
  margin-top: 1.5rem;
  color: #28a745;
  font-weight: bold;
  font-size: 1rem;
}

/* Styles pour le bouton retour */
.btn-back {
  display: block;
  margin: 20px auto;
  padding: 0.75rem 1.5rem;
  background-color: grey;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-back:hover {
  background-color: #333;
}
