.fade-away {
  animation: fadeOut 0.55s ease-out forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.intro-video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  z-index: 9999;
  transition: transform 0.5s ease-in-out;
}

.intro-video-container.fade-out {
  opacity: 0;
}

.intro-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fade-out {
  animation: fadeOut 1s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


.fullpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  position: relative;
}

.project-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  scroll-snap-align: center;
}

.project-list::after {
  content: '';
  position: absolute; left: 0; top: 100%;
  width: inherit;
  height: 5px;
  background: -moz-element();
  overflow: -moz-hidden-unscrollable;
}

.project-list::-webkit-scrollbar {
  display: none;
}

.project-item {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: center;
  transition: transform 0.5s ease-out;
  height: 100vh;
}

.video-container {
  position: relative;
  width: 27.5vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.video-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.video-title {
  writing-mode: vertical-rl;
  text-orientation: upright;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 12px;
  z-index: 2;
}

.project-list-mirror-container {
  height: 10vh;
  overflow: hidden;
  mask-image: linear-gradient(to bottom, rgb(255, 255, 255), rgba(0,0,0,0));
  -webkit-mask-image: linear-gradient(to bottom, rgb(255, 255, 255), rgba(0,0,0,0));
  margin-top: -4px;
}

.project-list-mirror {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80vh;
  overflow-y: hidden;
}

.project-item-mirror {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
}

.video-container-mirror {
  position: relative;
  width: 27.5vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}


.background-video-mirror {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  transform: scaleX(-1);
  transform: scaleY(-1);
  opacity: 0.8;
  filter: blur(3px);
}

.video-title-mirror {
  writing-mode: vertical-rl;
  text-orientation: upright;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1;
  z-index: 2;
  opacity: 1;
  filter: blur(4px);
}

.project-list-mirror::-webkit-scrollbar {
  display: none;
}

.ask-scroll {
  position: absolute;
  bottom: 30px;
}

@media (min-width: 2000px) {
  .video-title {
    font-size: 20px;
  }

}
@media (min-width: 1600px) {

  .video-container {
    width: 28vw;
  }

  .project-list-mirror-container {
    margin-top: -4px;
  }

  .video-container-mirror {
    width: 28vw;
  }

  .ask-scroll {
    bottom: 60px;
  }
}

@media (max-width: 500px) {

  .video-container {
    height: 65vh;
    width: 80vw;
  }

  .video-container-mirror {
    height: 65vh;
    width: 80vw;
  }

  .project-list-mirror {
    height: 65vh;
  }

  .project-list {
    margin-top: 5vh;
    height: 65vh;
  }

  .project-list-mirror-container {
    height: 12vh;
    margin-top: -1px;
  }

  .video-title {
    font-size: 8px;
  }

  .video-title-mirror {
    font-size: 8px;
  }

  .ask-scroll {
    position: absolute;
    bottom: 70px;
  }
}
