.contact-overlay {
  position: fixed;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FF696A;
  z-index: 1000;
  animation: contactSlideInFromBottom 0.5s ease-in-out;
  color: black;
}

@keyframes contactSlideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.contact-overlay.closing {
  animation: contactSlideoutFromBottom 0.5s ease-in-out;
}

@keyframes contactSlideoutFromBottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.logo-contact {
  position: absolute;
  background-image: url(../../Images/logo-contact.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 60px;
  top: 10px;
  left: 10px;
}

.catch-phrase {
  margin-bottom: 30px;
}

.email-numero {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  margin-bottom: 20px;

}

.email-numero h2 {
  font-style: bold;
  font-size: 2em;
  margin: 0;
}

.adress-logo {
  display: flex;
  justify-content: space-between;
}

.logos {
  display: flex;
  height: 35px;
  justify-content: space-between;
  gap: 2em;
}

.logo-contact-svg {
  height: 35px;
}

.adress {
  font-size: 0.9em;
  text-align: center;
}

.adress p {
  font-style: bold;
  margin: 0;
}


@media (min-width: 1000px) {


}

@media (max-width: 500px) {

  .contact-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FF696A;
    z-index: 999;
    animation: none;
  }

  @keyframes contactFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .contact-overlay.closing {
    animation: contactFadeOut 0.5s ease-in;
  }

  @keyframes contactFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .contact-content-info {
    height: 90%;
    margin-top: 15vh;
    overflow: scroll;

  }

  .catch-phrase {
    width: 100%;
    height: 45vh;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .catch-phrase h4 {
    width: 70%;
  }

  .email-numero  {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .email-numero h3 {
    font-size: 1.5em;
    font-style: bold;
    margin: 0;
  }

  .mobile-contact-image {
    background-image: url(../../Images/ladall-studio.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 45vh;
  }

  .adress-logo {
    flex-direction: column;
    justify-content: center;
    height: 45vh;
  }

  .adress {
    margin-bottom: 10px;
    font-size: 16px;
  }
};
