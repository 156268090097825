/* MobileMenu.css */
.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9001;
  overflow: hidden;
  transition: 1s;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-menu li {
  text-align: center;
}

.mobile-menu a {
  text-decoration: none;
  color: white;
  font-size: 32px;
}

.mobile-menu a:hover {
  color: rgb(156, 54, 156);
}

.close-menu {
  position: absolute;
  top: 30px;
  right: 20px;
  background: transparent;
  border: none;
  color: white;
  font-size: 24px;
}
