.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  opacity: 1;
  transition: opacity 0.5s ease;
  z-index: 1001;
}

.loading-logo-page {
  position: absolute;
  top: 20px;
  width: 150px;
  height: 200px;
  background-image: url('../../Images/logo-ladall-full-white.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.loading-page h5 {
  margin: 0;
}

.loading-page small {
  position: absolute;
  bottom: 20px;
}

.loading-page.hide {
  opacity: 0;
  pointer-events: none;
}
