.template4-media {
  grid-column: 1 / 9;
  width: 100%;
  height: 50vh;
  aspect-ratio: 9 / 16;
  margin-bottom: 10vh;
}

.template4-media video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.template4-second-media {
  grid-column: 2 / 10;
  display: flex;
  height: 90vh;
  justify-content: center;
  gap: 0;
  margin-bottom: 10vh;
}

.template4-second-media video {
  width: calc(50%);
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: contain;
}

.template4-third-media {
  grid-column: 3 / 11;
  margin-bottom: 10vh;
  width: 100%;
  height: 50vh;
  aspect-ratio: 9 / 16;
}

.template4-third-media video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.template4-fourth-media {
  grid-column: 3 / 9;
  aspect-ratio: 16 / 9;
  height: 90vh;
}

.template4-fourth-media-image {
  grid-column: 1 / 9;
  aspect-ratio: 16 / 9;
  height: 90vh;
}

.template4-fourth-media video {
  width: calc(40%);
  height: auto;
  object-fit: contain;
  margin-left: 7.5%;
}

.template4-image-1 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.template4-image-2 {
  width: calc(50%);
  height: auto;
  aspect-ratio: 16 / 9;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.template4-image-3 {
  width: calc(50%);
  height: auto;
  aspect-ratio: 16 / 9;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.template4-image-4 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.template4-image-5 {
  width: calc(90%);
  height: auto;
  aspect-ratio: 16 / 9;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.video {
  pointer-events: none;
}

@media (min-width: 2000px)  {
  .template4-second-media video,
  .template4-image-2,
  .template4-image-3 {
    width: calc(42%);
  }
}

@media (min-width: 1600px)  {
  .template4-second-media {
    height: 93vh;
  }
}
