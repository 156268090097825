@font-face {
  font-family: "Neue Montreal Regular";
  src: url('./Fonts/NeueMontreal-Regular.ttf') format("ttf"),
       url('./Fonts/NeueMontreal-Regular.woff') format("woff"),
       url('./Fonts/NeueMontreal-Regular.woff2') format("woff2");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Neue Montreal Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  /* background: linear-gradient(to bottom, #000000 0%, #010423 50%, #000000 100%); */
  background-color: black;
  background-image: url('./Images/background-ladal-v3.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: none;
}

.fixed-video {
  position: fixed;
  top: 42%;
  right: 7%;
  width: 3%;
  height: auto;
  z-index: 999;
  pointer-events: none;
  filter: blur(1.5px);
}

.blackout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 9998;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
}

.blackout.visible {
  opacity: 1;
}

body::-webkit-scrollbar {
  display: none;
}

a, button, [role="button"], input, select, textarea {
  cursor: none;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
