.content::-webkit-scrollbar {
  display: none;
}

.single-project {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  overflow-x: hidden;
}

.slide-out-up {
  animation: fadeOut 0.6s ease-in forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.slide-in-down {
  animation: fadeIn 1s  ease-in forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;

  }
  100% {
    opacity: 1;
  }
}

.single-project-title {
  position: relative;
  height: 90vh;
  grid-column: 1 / 11;
  margin-top: 20px;
  margin-bottom: 20px;
}

.single-project-title::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.single-project-title video {
  position: absolute;
  top: 0;
  left: 0;
  width: 99%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

iframe {
  pointer-events: none;
}

.single-project-title h1 {
  position: relative;
  z-index: 1;
}

.descriptions {
  grid-column: 6 / 10;
  text-align: left;
  margin-bottom: 5vh;
}

.project-title {
  font-size: 1.1em;
}

.project-description {
  font-size: 1.8em;
  line-height: 1.2;
}

.descriptions span {
  margin-right: 15px;
  font-size: 0.8em;
}

.project-type {
  font-size: 0.8em;
  margin-bottom: -1px;
}

.project-agence {
  color: white;
}

.media {
  grid-column: 2 / 10;
  display: flex;
  margin-bottom: 5vh;
  justify-content: center;
  height: 90vh;
  gap: 0;
}

.media video {
  object-fit: contain;
}

.template1-image-1,
.template1-image-2 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.media video,
.template1-image-1,
.template1-image-2 {
  width: calc(50%); /* Ajuste la largeur pour les éléments dans .media */
  height: auto; /* Assure que la hauteur est calculée en fonction de la largeur */
  aspect-ratio: 16 / 9; /* Assure un ratio 16:9 */
  margin: 0;
}

.second-media {
  grid-column: 4 / 11;
  margin-bottom: 10vh;
  width: 100%;
  height: 50vh;
  aspect-ratio: 9 / 16;
}

.second-media video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.template1-image-3 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.third-media {
  grid-column: 1 / 8;
  margin-bottom: 5vh;
  width: 100%;
  height: 50vh;
  aspect-ratio: 9 / 16;
}

.third-media video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.template1-image-4 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.fourth-media {
  grid-column: 2 / 10;
  display: flex;
  margin-bottom: 10vh;
  height: 90vh;
  justify-content: center;
  gap: 0;
}

.fourth-media video {
  object-fit: contain;
}

.template1-image-5,
.template1-image-6 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.fourth-media video,
.template1-image-5,
.template1-image-6 {
  width: calc(50%);
  height: auto;
  aspect-ratio: 16 / 9;
  margin: 0
}

.video {
  pointer-events: none;
}

.buttons-container {
  grid-column: 4 / 8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  position: relative;
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  position: relative;
}

.next-project-text {
  grid-column: 4 / 5;
}

.next-project-button {
  height: 50px;
  width: 50px;
  background-image: url('../../../Images/mouse_ladall-etat03.png');
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  margin-bottom: 35px;
  cursor: none !important;
}

.next-project-button:hover {
  background-image: url('../../../Images/mouse_ladall-etat02.png');
}

.prev-project-button {
  height: 50px;
  width: 50px;
  background-image: url('../../../Images/mouse_ladall-etat03-inversée.png');
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  margin-bottom: 35px;
  cursor: none !important;
}

.prev-project-button:hover {
  background-image: url('../../../Images/mouse_ladall-etat02-inversée.png');
}

@media (min-width: 2000px)  {
  .media video,
  .template1-image-1,
  .template1-image-2 {
    width: calc(42%);
  }

  .fourth-media video,
  .template1-image-5,
  .template1-image-6 {
    width: calc(42%);
  }
}
@media (min-width: 1600px)  {
  .descriptions {
    grid-column: 6 / 9;
    text-align: left;
    margin-bottom: 7vh;
    margin-top: 7vh;
  }

  .media {
    height: 93vh;
  }

  .fourth-media {
    height: 93vh;
  }
}

@media (max-width: 500px) {

  .single-project {
    margin-top: 12vh;
    height: 90%;
    overflow-x: scroll;
  }

  .single-project-title {
    height: 50vh;
    margin-left: 10px;
    margin-right: 10px;
  }

  .descriptions {
    grid-column: 1 / 11;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5vh;
  }

  .mobile-media {
    grid-column: 1 / 11;
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin-left: 10px;
    margin-right: 10px;
  }

  .mobile-media video{
    width: auto;
    height: 40vh;
    object-fit: contain;
    margin-bottom: 5vh;
  }


  .mobile-template-image {
    width: auto;
    height: 40vh;
    margin-bottom: 5vh;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .buttons-container {
    gap: 15px;
    margin-bottom: 5vh;
  }

  .next-project-text {
    text-align: center
    ;
  }

  .next-project-button {
    height: 40px;
    width: 40px;
  }

  .prev-project-button {
    height: 40px;
    width: 40px;
  }

  .next-project-button:active {
    background-image: url('../../../Images/mouse_ladall-etat02.png');
  }

  .prev-project-button:active {
    background-image: url('../../../Images/mouse_ladall-etat02-inversée.png');
  }
}
