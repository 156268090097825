.custom-cursor {
  position: fixed;
  width: 30px;
  height: 30px;
  pointer-events: none;
  z-index: 9998;
  will-change: transform;
}

.custom-cursor img {
  width: 100%;
  height: 100%;
}

.hide-custom-cursor .custom-cursor {
  display: none; /* Masque le curseur personnalisé */
}

.custom-cursor.hover {
  background-image: url('../../Images/mouse_ladall-etat02.png');
  background-size: cover;
}

.custom-cursor.hover img {
  display: none;
}
