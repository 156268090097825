.layout {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.black-background {
  background-color: black;
  color: white;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.sidebar.left {
  left: 0;
  z-index: 998;
}

.sidebar.right {
  right: 0;
}


.logo {
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  top: 5px;
  left: 10px;
  z-index: 9999;
}

.link {
  text-decoration: none;
  color: white;
  transition: 0.2s;
  margin: 20px 0;
}

.link:hover {
  color: rgb(156, 54, 156);
}

.studio-link {
  position: absolute;
  top: 10px;
  right: 30px;
  font-size: 36px;
  z-index: 9999;
}

.studio-link:hover {
  color: #5C5CDE;
}

.studio-return-link {
  position: absolute;
  top: 10px;
  right: 30px;
  font-size: 36px;
  z-index: 9999;
}

.studio-return-link:hover {
  color: #FF696A;
}


.contact-link {
  position: absolute;
  bottom: 10px;
  right: 30px;
  font-size: 36px;
  z-index: 9999;
}

.contact-link:hover {
  color: #FF696A;
}

.contact-return-link {
  position: absolute;
  bottom: 10px;
  right: 30px;
  font-size: 36px;
  z-index: 9999;
}

.contact-return-link:hover {
  color: #5C5CDE;
}

.studio-link:hover,
.studio-return-link:hover,
.contact-link:hover {
  transform: rotate(0.10turn);
}

.menu-link:active {
  transform: rotate(0.10turn);
}


.content {
  flex-grow: 1;
  margin-left: 150px;
  margin-right: 150px;
  overflow: auto;
}

.creer {
  position: absolute;
  bottom: 50px;
  right: 10px;
}

.modifier {
  position: absolute;
  bottom: 90px;
  right: 10px;
}

.supprimer {
  position: absolute;
  bottom: 160px;
  right: 10px;
}

@media (min-width: 1600px) {

  .logo {
    top: 15px;
    left: 25px;
  }
}

@media (max-width: 500px) {

  .studio-link:hover,
  .studio-return-link:hover,
  .contact-link:hover {
  transform: rotate(0);
}

  .logo {
    top: 1px;
    width: 60px;
    height: 95px;
    background-repeat: no-repeat;
  }

  .logo-video {
    width: 70px;
    height: 75px;
  }

  .sidebar.left {
    position: fixed;
    top: 0;
    left: 0;
    bottom: auto;
    width: 100%;
  }

  .sidebar.right {
    position: fixed;
    top: 0;
    right: 0;
    bottom: auto;
    width: 100%;
  }

  .studio-link {
    position: absolute;
    top: 10px;
    right: 30px;
    font-size: 12px;
  }

  .menu-link {
    position: absolute;
    top: 10px;
    right: 30px;
    font-size: 24px;
    z-index: 1000;
  }

  .studio-return-link {
    font-size: 12px;
  }

  .contact-link {
    position: absolute;
    top: 40px;
    right: 30px;
    font-size: 12px;
  }

  .contact-return-link {
    top: 40px;
    right: 30px;
    font-size: 12px;
  }

  .content {
    flex-grow: 1;
    margin-left: 0;
    margin-right: 0;
    overflow: auto;
  }
}
