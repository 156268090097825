.update-project-container {
  width: 80vw;
  height: 90vh;
  background-color: rgb(34, 62, 105);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 3px 15px -5px rgba(0, 0, 0, 0.7);
  overflow: auto;
  box-sizing: border-box;
  margin-top: 5vh;
  cursor: auto;
}

.update-project-form {
  display: grid;
  gap: 15px; /* Réduction de l'espacement entre les éléments */
}

.update-project-form label {
  font-size: 16px; /* Réduction de la taille de la police */
  color: white;
  margin-bottom: 4px; /* Espacement réduit sous les labels */
}

.update-project-form input[type="text"],
.update-project-form textarea,
.update-project-form input[type="file"] {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.update-project-form input[type="file"] {
  background-color: #FFF;
}

.form-group input[type="file"].filled {
  background-color: #308344;
  border-color: #154721;
}

.update-project-form textarea {
  resize: vertical;
  min-height: 120px;
}

.update-project-form button {
  background-color: #950707;
  color: white;
  font-size: 14px;
  border: none;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ddd;
  display: inline-block;
  text-align: center;
}

.update-project-form button:hover {
  background-color: #591010;
}

.update-project-form .message {
  color: white;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
}

.template-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}


.template-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 18%;
}


.template-item input[type="radio"] {
  margin-bottom: 10px;
}


.template-preview {
  max-width: 70px;
  height: auto;
}

.file-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}


.file-container .form-group {
  flex: 1 1 150px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .update-project-container {
    width: 95%;
    padding: 15px;
  }

  .update-project-form {
    gap: 10px;
  }

  .update-project-form label {
    font-size: 14px;
  }

  .update-project-form input[type="text"],
  .update-project-form textarea,
  .update-project-form input[type="file"],
  .update-project-form button {
    padding: 6px;
  }

  .update-project-form textarea {
    min-height: 100px;
  }

  .update-project-form button {
    font-size: 12px;
  }

  .update-project-form .message {
    font-size: 10px;
  }
}
