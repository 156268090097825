.studio-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background-color: #5C5CDE;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  animation: studioSlideInFromTop 0.5s ease-out;
}

@keyframes studioSlideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.studio-overlay.closing {
  animation: studioSlideOutToTop 0.5s ease-in;
}

@keyframes studioSlideOutToTop {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

.studio-content {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.background-image-studio {
  grid-column: 2 / 6;
  background-image: url(../../Images/ladall-studio.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-self: center;
  justify-self: center;
  width: 60%;
  height: 95%;
}

.text {
  grid-column: 6 / 11;
  margin: 0;
}

.text p {
  margin: 0;
}

.showShowreel {
  display: inline-block;
  margin-top: 30px;
  padding: 5px 10px;
  color: #5C5CDE;
  background-color: white;
}

.showShowreel:hover {
  color: white;
  background-color: #FF696A;
  transform: rotate(0.99turn);
}

.logo-studio {
  position: absolute;
  background-image: url(../../Images/logo-ladall-full-white.png);
  background-position: center;
  background-size: contain;
  width: 100px;
  height: 130px;
  top: 10px;
  left: 10px;
}

.studio-contact-link {
  position: absolute;
  bottom: 10px;
  right: 30px;
  font-size: 36px;
}

.contact-link:hover {
  color: #FF696A;
}

.showreel-video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: auto;
}
.showreel-video-container iframe {
  pointer-events: auto; /* Permet l'interaction avec les contrôles de la vidéo */
}

.showreel-video-container {
  position: relative;
  width: 90%;
  height: 90%;
}



.showreel-close-button {
  position: absolute;
  top: -30px;
  right: -30px;
  background: none;
  border: solid;
  border-color: white;
  border-radius: 45%;
  color: white;
  font-size: 20px;
}

.showreel-close-button:hover {
  border-color: rgb(145, 36, 36);
  color: rgb(145, 36, 36);
}


@media (min-width: 2000px) {
  .studio-overlay {
    height: 60%;
  }

  .text {
    grid-column: 6 / 9;
  }

  .background-image-studio {
    width: 40%;
    height: 165%;
  }
}

@media (max-width: 500px) {

  .studio-content {
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    height: 80%;
    margin-top: 20vh;
    overflow: scroll;
  }

  .studio-overlay {
    height: 100%;
    animation: none;
  }

  @keyframes studioFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .studio-overlay.closing {
    animation: studioFadeOut 0.5s ease-in;
  }

  @keyframes studioFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .text {
    grid-column: 2 / 13;
    margin-right: 10px;
    font-size: 20px;
  }

  .artist {
    font-size: 12px;
  }

  .logo-studio {
    width: 50px;
    height: 60px;
  }

  .showShowreel {
    font-size: 26px;
    margin-bottom: 50px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  .showShowreel:active {
    color: white;
    background-color: #FF696A;
    transform: rotate(0.99turn);
  }

  .studio-contact-link {
    position: absolute;
    top: 5px;
    right: 30px;
    font-size: 36px;
  }

  .studio-contact-link:hover {
    color: #FF696A;
  }

  .studio-return-link {
    position: absolute;
    top: auto;
    bottom: 10px;
    right: 30px;
    font-size: 24px;
  }
};
