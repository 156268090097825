.template5-media {
  grid-column: 4 / 10;
  margin-bottom: 5vh;
  width: 100%;
  height: 50vh;
  aspect-ratio: 9 / 16;
}

.template5-media video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.template5-second-media {
  grid-column: 2 / 8;
  width: 100%;
  height: 50vh;
  aspect-ratio: 9 / 16;
  margin-bottom: 5vh;
}

.template5-second-media video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.template5-third-media {
  grid-column: 2 / 10;
  display: flex;
  height: 90vh;
  justify-content: center;
  gap: 0;
  margin-bottom: 5vh;
}

.template5-third-media video {
  width: calc(50%);
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: contain;
}

.template5-fourth-media {
  grid-column: 3 / 9;
  width: 100%;
  height: 50vh;
  aspect-ratio: 9 / 16;
  margin-bottom: 5vh;
}

.template5-fourth-media video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.template5-fifth-media {
  grid-column: 4 / 10;
  margin-bottom: 5vh;
  width: 100%;
  height: 50vh;
  aspect-ratio: 9 / 16;
}

.template5-fifth-media video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.template5-image-1 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.template5-image-2 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.template5-image-3 {
  width: calc(50%);
  height: auto;
  aspect-ratio: 16 / 9;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.template5-image-4 {
  width: calc(50%);
  height: auto;
  aspect-ratio: 16 / 9;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.template5-image-5 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.template5-image-6 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.video {
  pointer-events: none;
}

@media (min-width: 2000px)  {
  .template5-third-media video,
  .template5-image-3,
  .template5-image-4 {
    width: calc(42%);
  }
}
@media (min-width: 1600px)  {
  .template5-third-media {
    height: 93vh;
  }
}
